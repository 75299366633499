<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div id="background-container">
                <div class="level-item">
                    <h1 class="title is-1 page-title">TRAVEL INSURANCE FOR POPULAR DESTINATIONS</h1>
                </div>
                <p class="top-text">
                    Wherever you’re heading on holiday, we have prepared plenty of information to ensure you are prepared for your trip. 
                </p>
                <p class="top-text">
                    Our helpful destination guides will help you figure out the travel insurance cover you need, simply click on the destination you’re jetting off to below.
                </p>
                <p class="blue-text">
                    Looking for a travel insurance quote?
                </p>
                <p class="top-text">
                    <router-link class="button is-primary hvr-glow" :to="{name:'getQuote'}">
                        <strong>GET A QUOTE</strong>
                    </router-link>
                </p><br/><br/>
                <div class="columns is-multiline content">
                    <div class="column is-6 area-padding">
                        <div class="uk-card center_css">
                            <div>
                                <a href="/uk-travel-insurance">
                                    <h2 class="destination-header">UK</h2>
                                    <p class="destination-text">Read more about travel insurance for the UK</p>   
                                </a>    
                            </div>
                        </div>
                    </div>
                    <div class="column is-6 area-padding">
                       <div class="dubai-card center_css">
                            <div>
                                <a href="/dubai-travel-insurance">
                                    <h2 class="destination-header">DUBAI</h2>    
                                    <p class="destination-text">Read more about travel insurance for Dubai</p>   
                                </a>    
                            </div>
                        </div>
                    </div>
                    <div class="column is-6 area-padding">
                        <div class="spain-card center_css">
                            <div>
                                <a href="/spain-travel-insurance">
                                    <h2 class="destination-header">SPAIN</h2>    
                                    <p class="destination-text">Read more about travel insurance for Spain</p>  
                                </a>
                            </div>     
                        </div>
                    </div>
                    <div class="column is-6 area-padding">
                        <div class="usa-card center_css">
                            <div>
                                <a href="/usa-travel-insurance">
                                    <h2 class="destination-header">USA</h2>    
                                    <p class="destination-text">Read more about travel insurance for the USA</p>   
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6 area-padding">
                        <div class="euro-card center_css">
                            <div>
                                <a href="/europe-travel-insurance">
                                    <h2 class="destination-header">EUROPE</h2>    
                                    <p class="destination-text">Read more about travel insurance for Europe</p>   
                                </a>
                            </div>    
                        </div>
                    </div>
                    <div class="column is-6 area-padding">
                        <div class="ww-card center_css">
                            <div>
                                <a href="/worldwide-travel-insurance">
                                    <h2 class="destination-header">WORLDWIDE</h2>    
                                    <p class="destination-text">Read more about travel insurance for Worldwide</p>   
                                </a>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "TravelByDestination",
        data() {return {}},
        methods: {},
        components: {},
        created() {}
    }
</script>

<style lang="scss" scoped>
    .page-title {color: $c2g_orange;}
    .top-text {margin-top: 10px; text-align:center;}
    .blue-text {margin-top: 10px; text-align:center; color: $c2g_blue; font-weight: bold; font-size:120%;}
    .uk-card {background: url('../assets/images/info/destination_uk.jpg') no-repeat;height: 300px;background-size: 100% 100%;}
    .dubai-card {background: url('../assets/images/info/destination_dubai.jpg') no-repeat;height: 300px;background-size: 100% 100%;}
    .usa-card {background: url('../assets/images/info/destination_usa.jpg') no-repeat;height: 300px;background-size: 100% 100%; }
    .euro-card {background: url('../assets/images/info/destination_europe.jpg') no-repeat;height: 300px;background-size: 100% 100%;}
    .ww-card {background: url('../assets/images/info/destination_worldwide.jpg') no-repeat;height: 300px;background-size: 100% 100%;}
    .spain-card {background: url('../assets/images/info/destination_spain.jpg') no-repeat;height: 300px;background-size: 100% 100%;}
    .destination-header{color:white; text-align:center; font-size:250%; margin-top:50px; text-shadow: 3px 3px #000000;};
    .destination-text{color: white; font-weight:bold; font-size:120%; margin-top:30px; text-shadow: 2px 2px #000000;}
    .center_css{display:flex; justify-content: center; align-items: center;}
    .area-padding{padding-right:0px; padding-left:0px; padding-top:0px; padding-bottom:0px;}
</style>